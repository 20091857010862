import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };
  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='RELIABLE HEALTH INSURANCE IN FLORIDA' activeCard='florida' image={'/Colorado_BG.jpeg'}>
          <p>
            Apollo Insurance works hard to find the best providers of reliable health insurance in Florida at the lowest cost to our customers. Our agents work
            with companies like Ambetter, an insurance provider with significant coverage across the United States. To better provide the best health insurance
            for our customers, Apollo Insurance also works closely with Aliera, National General, and United Healthcare to make sure you have access to the
            plans you need. We work with Florida agents to find off-exchange carriers for customers who prefer that option. Whether you are looking for life
            insurance or a quality dental plan, Apollo Insurance can help!
          </p>
          <p>
            Small businesses in Florida also benefit from our affordable group health insurance options. We are fueled by our passion to help our clients find
            the best solutions to reliable health insurance in Florida. This is a rapidly-evolving environment, but you can rely on Apollo Insurance to safely
            guide you through the fundamentals of your health insurance plan. We’re looking forward to working with you – contact us today!
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
